import { Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TrustSection from "../components/sections/trustSection"
import HeadingGroup from "../components/ui/headingGroup"
import Banner from "../components/ui/banner"
import GetStartedSection from "../components/sections/getStartedSection"
import RequestDemoDrawer from "../components/drawers/requestDemoDrawer";
import SignUpDrawer from "../components/drawers/signUpDrawer";
import PricingDrawer from "../components/drawers/pricingDrawer";
import PageBreak from "../components/ui/pageBreak";
import ImageGroup from "../components/ui/imageGroup";
import styled from "styled-components";
import PrimaryButton from "../components/ui/primaryButton";

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const ConstructionPage = () => {
  const [openPriceCalculator, setOpenPriceCalculator] = useState(false);
  const [openRequestDemo, setOpenRequestDemo] = useState(false);
  const [openGettingStarted, setOpenGettingStarted] = useState(false);

  const handlePriceCalculatorClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenPriceCalculator(!openPriceCalculator);
      event.preventDefault();
    }
  }

  const handleRequestDemoClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenRequestDemo(!openRequestDemo);
      event.preventDefault();
    }
  }

  const handleGettingStartedClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenGettingStarted(!openGettingStarted);
      event.preventDefault();
    }
  }

  return (
    <Layout>
      <SEO title="Construction Biometric Solutions" />
      <Banner>
        <p>
          Are you looking for an immediate price estimate?&nbsp;<Link to="/pricing" onClick={handlePriceCalculatorClick}>Price Calculator</Link>
        </p>
      </Banner>
      <Hero
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick}
        statement="Does your site require"
        punchlines={["Time and Attendance?", "Remote Site Control?", "Real Time Monitoring?", "Shift Control?", "Payroll Integration?", "Portable Hardware?"]}
        caption="We provide cloud-based Access Control as well as Time and Attendance solutions which rely on fingerprint or facial recognition. Track your employees' attendance against configurable work rules and integrate directly into your payroll provider."
      ></Hero>
      <TrustSection />
      <HeadingGroup heading="Biometric Attendance Tracking for Construction and Mining">
        <p>
          Beyond Attendance fulfils multiple requirements within a single platform for the Construction industry.
        </p>
        <p>
          The rapid and accurate collection of attendance and leave records eliminates the need for manual data collection. This is especially powerful when managing people spread across multiple sites.
        </p>
        <p>
          We have a wide range of mobile scanning solutions perfectly suited to teams working on temporary sites. Once work at a site is finished, the scanning system is transported to the next site, ready to work. These options include both portable biometric systems and mobile apps. We also offer a range of facial recognition and RFID tag solutions when fingerprint quality suffers due to the natural of manual labour.
        </p>
      </HeadingGroup>
      <PageBreak>Take a look at some of our key features below&nbsp;<span role="img" aria-label="fire">🔥</span></PageBreak>

      <ImageGroup reverse={false} image="../images/pricing.svg" heading="Competitive Pricing">
        <p>
        Our product has a scaled pricing model which allows you to choose the Time and Attendance tracking package that best suits your needs and offers maximum value for money.  The system is a month to month contract so you are not locked in by fixed terms and your monthly bill will depend on the number of employees on the system.
        </p>
        <ButtonContainer>
          <PrimaryButton to="/pricing" onClick={handlePriceCalculatorClick} padding="0.8rem" margin="0.8rem 0.8rem 0.8rem 0" fontSize="1rem">Price Calculator</PrimaryButton>
        </ButtonContainer>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/portable.svg" heading="Portable Biometric Options">
        <p>
          Our Time and Attendance solutions include different types of portable hardware, to suit your needs, that allow employees to clock in without needing a fixed device in place. For teams that frequently move around or work on remote sites, our BA922 device has a built-in lithium ion battery which supports both GMS and WiFi communication protocols. We also have a USB fingerprint device that allows any Android device to become a mobile biometric scanning station, perfect for small mobile teams.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/multisite.svg" heading="Multisite Control">
        <p>
          Due to the system being cloud-based, multiple sites can be controlled from a single centralised location. The devices link up in real-time allowing for immediate data retrieval. This ensures transparency in the workplace and eliminates the need to wait for managers to manually send time sheets and other documentation to head office. The instant reporting saves time and allows for quick action to be taken if necessary.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/configurable.svg" heading="24 Hour Work Compatibility">
        <p>
          Beyond Attendance supports both day and night shift configurations and can accommodate work shifts that run continually. Combined with our USB fingerprint scanner, teams can run autonomously while data is sent back to a single centralised administration point in real time.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/configurable.svg" heading="Job Code Tracking">
        <p>
          Beyond Attendance allows for hours worked to be assigned to specific job codes. When employees are scanning in via our Android Portal app, supervisors can specify the job code currently being worked on. The total hours for the various ongoing projects can then be tallied and processed by administration staff or via 3rd party pieces of software.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/role.svg" heading="Role Based Access">
        <p>
          Beyond Attendance allows for multiple levels of control to be granted to different users. For example, a site manager can be granted access that allows them to request modifications to work times and shifts. These requests would then need to be approved or denied by an area manager or an administrator at head office. We can also limit which functions of the software are accessed. A reporter can generate and view reports while an HR clerk has the ability create profiles for new people.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/configurable.svg" heading="Configurable Shift Rules">
        <p>
          Work rules such as automatic deduction of lunch and tea breaks, removal of duplicated scans and automated rounding of start and end times, can be customized to suit each company uniquely. Using Machine Learning Technology, our system's Shift Sense Function will automatically detect and assign shifts based on employees' start and end scans.
        </p>
      </ImageGroup>

      <GetStartedSection
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick} />

      <RequestDemoDrawer open={openRequestDemo} handleClose={() => setOpenRequestDemo(false)} />
      <SignUpDrawer open={openGettingStarted} handleClose={() => setOpenGettingStarted(false)} />
      <PricingDrawer open={openPriceCalculator} handleClose={() => setOpenPriceCalculator(false)} />
    </Layout>
  )
}

export default ConstructionPage
